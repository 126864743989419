import React from 'react';
import PropTypes from 'prop-types';

import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import {css} from '@emotion/react';
import styled from '@emotion/styled';

import {BaseHomepageCardLink} from './BaseHomepageCards';
import {boxShadowPerformantCss} from '../common/CardBase';
import {Hero} from '../common/Typography';
import {breakpoints, colors, ContentContainer} from '../../styles/theme';
import Background from '../../images/homepage/elevate-background.png';
import ScalabilityIcon from '../../images/homepage/scalability.png';
import SecurityIcon from '../../images/homepage/security.png';
import StabilityIcon from '../../images/homepage/stability.png';
import SupportIcon from '../../images/homepage/support.png';
import CreditoAgricolaLogo from '../../images/homepage/customers/credito-agricola.svg';
import LuzSaudeLogo from '../../images/homepage/customers/luz-saude.svg';
import PayshopLogo from '../../images/homepage/customers/payshop.svg';
import ProzisLogo from '../../images/homepage/customers/prozis.svg';
import TapLogo from '../../images/homepage/customers/tap.svg';


/*
 * Private Elements
 */
const StyledElevateYourBusiness = styled.div`
    background-color: ${colors.white};
    color: ${colors.black};

    ${ContentContainer} {
        padding-top: 50px;
        padding-bottom: 50px;
        background: url(${Background}) no-repeat scroll center top;
        background-size: calc(100% - 50px);

        ${Hero} {
            margin-top: 30px;
            margin-bottom: 80px;
        }

        .row {
            margin-bottom: 20px;
        }

        .cards {
            & > .row {
                margin-bottom: 0;
            }
        }

        .customers {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding-top: 25px;
            margin-bottom: 0;
          
            .costumer-logo-wrapper {
                display: grid;
                place-content: center;
                height: 60px;
                width: 50%;
                padding: 0 10px;
                margin-bottom: 2rem;
                
                svg {
                    height: auto;
                    width: auto;
                    max-height: 100%;
                    max-width: 100%;
                }
            }
        }
    }

    @media (min-width: ${breakpoints.md}) {
        ${ContentContainer} {
            padding-top: 110px;
            padding-left: 65px;
            padding-right: 65px;
            background: none;

            ${Hero} {
                margin: 0;
            }

            .title {
                background: url(${Background}) no-repeat scroll left 10px;
                background-size: 75%;
            }

            .customers {
                .costumer-logo-wrapper {
                    width: 20%;
                    padding: 0 15px;
                    margin-bottom: 0;
                }
            }
        }
    }
`;

const ElevateBusinessCardCss = css`
    ${boxShadowPerformantCss};
    background-color: ${colors.white};
    box-shadow: -3px 6px 10px 0 ${colors.shadowGrey};
    padding: 25px;

    ::after {
        box-shadow: -9px 16px 20px 0 ${colors.shadowGrey};
        opacity: 0;
        transition: opacity 400ms ease-in-out;
    }
`;

const ElevateBusinessCard = ({title, image, description, url, linkTitle}) => (
    <BaseHomepageCardLink
        cssStyles={ElevateBusinessCardCss}
        title={title}
        image={image}
        description={description}
        url={url}
        linkTitle={linkTitle}
        coloredCta
    />
);

ElevateBusinessCard.propTypes = {
    title: PropTypes.string.isRequired,
    image: PropTypes.node.isRequired,
    description: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    linkTitle: PropTypes.string.isRequired,
};


/*
 * Public Elements
 */
const ElevateYourBusiness = () => {
    const {t} = useTranslation();

    const securityCard = {
        title: t('Security'),
        image: SecurityIcon,
        description: t(
            'Switch is compliant with the latest security and privacy standards/legislation, so you don’t have to. We '
            + 'improve the security of your data continuously, following the latest security standards and guidelines '
            + 'and implementing state-of-the-art measures.',
        ),
        url: 'https://switchpayments.com/docs/overview',
        linkTitle: t('Protect your data'),
    };
    const stabilityCard = {
        title: t('Stability'),
        image: StabilityIcon,
        description: t(
            'Payments are often the most critical part of a software application, and they should work even when the '
            + 'rest of your product is down. Our infrastructure is optimised for stability, and alerts you when '
            + 'there\'s a failure in your system or in any payment provider.',
        ),
        url: 'https://switchpayments.com/docs/overview#stability-and-scalability',
        linkTitle: t('Ensure zero downtime'),
    };
    const scalabilityCard = {
        title: t('Scalability'),
        image: ScalabilityIcon,
        description: t(
            'Large volume businesses have so much data that it can be a costly and challenging engineering task to '
            + 'scale its infrastructure. Switch uses state-of-the-art technology to handle and process big data.',
        ),
        url: 'https://switchpayments.com/docs/overview#stability-and-scalability',
        linkTitle: t('Grow without worrying'),
    };
    const supportCard = {
        title: t('Support'),
        image: SupportIcon,
        description: t(
            'Switch grants specialised payment management support, making sure you connect with the right providers to '
            + 'optimize your payments operation. When something goes wrong with payments, you want fast answers, we '
            + 'offer a dedicated account manager and 24/7 support SLAs.',
        ),
        url: '/about/contact',
        linkTitle: t('Get the support you deserve'),
    };

    return (
        <StyledElevateYourBusiness>
            <ContentContainer>
                <div className="row no-gutters">
                    <div className="col-12 col-md-5 col-xl-4 title">
                        <Hero><Trans>Elevate your business by trusting ours.</Trans></Hero>
                    </div>

                    <div className="col-12 col-md-7 col-xl-8 cards">
                        <div className="row no-gutters">
                            <div className="row no-gutters col-12 col-lg-6">
                                <ElevateBusinessCard
                                    title={securityCard.title}
                                    image={securityCard.image}
                                    description={securityCard.description}
                                    url={securityCard.url}
                                    linkTitle={securityCard.linkTitle}
                                />
                            </div>
                            <div className="row no-gutters col-12 col-lg-6">
                                <ElevateBusinessCard
                                    title={stabilityCard.title}
                                    image={stabilityCard.image}
                                    description={stabilityCard.description}
                                    url={stabilityCard.url}
                                    linkTitle={stabilityCard.linkTitle}
                                />
                            </div>
                        </div>

                        <div className="row no-gutters">
                            <div className="row no-gutters col-12 col-lg-6">
                                <ElevateBusinessCard
                                    title={scalabilityCard.title}
                                    image={scalabilityCard.image}
                                    description={scalabilityCard.description}
                                    url={scalabilityCard.url}
                                    linkTitle={scalabilityCard.linkTitle}
                                />
                            </div>
                            <div className="row no-gutters col-12 col-lg-6">
                                <ElevateBusinessCard
                                    title={supportCard.title}
                                    image={supportCard.image}
                                    description={supportCard.description}
                                    url={supportCard.url}
                                    linkTitle={supportCard.linkTitle}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row no-gutters customers">
                    <div className="costumer-logo-wrapper">
                        <CreditoAgricolaLogo />
                    </div>
                    <div className="costumer-logo-wrapper">
                        <LuzSaudeLogo />
                    </div>
                    <div className="costumer-logo-wrapper">
                        <PayshopLogo />
                    </div>
                    <div className="costumer-logo-wrapper">
                        <TapLogo />
                    </div>
                    <div className="costumer-logo-wrapper">
                        <ProzisLogo />
                    </div>
                </div>
            </ContentContainer>
        </StyledElevateYourBusiness>
    );
};


/*
 * Exports
 */
export default ElevateYourBusiness;
