import React, {useContext} from 'react';

import {Helmet, Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import {css} from '@emotion/react';

import SEO from '../components/common/SEO';
import {EndOfPageCTA} from '../components/common/Clickables';
import WithAndWithoutSwitch from '../components/homepage/WithAndWithoutSwitch';
import OurSolutionsYourChoice from '../components/homepage/OurSolutionsYourChoice';
import BuiltForDevelopers from '../components/homepage/BuiltForDevelopers';
import ElevateYourBusiness from '../components/homepage/ElevateYourBusiness';
import {H2, H4Mono} from '../components/common/Typography';
import {colors, ContentContainer, fontWeights} from '../styles/theme';
import {ContrastContext} from '../helpers/context';


/*
 * Private Elements
 */
const ContactSalesCTAStyles = css`
    color: ${colors.white};
    margin-top: 50px;

    ${ContentContainer} {
        .cta {
            padding-top: 60px;
            padding-bottom: 80px;
            background: linear-gradient(to bottom right, ${colors.black}, ${colors.darkPurple}, ${colors.purple});

            ${H2} {
                font-size: 36px;
                line-height: 48px;
                margin-bottom: 20px;

                .thin {
                    font-weight: ${fontWeights.semiLight};
                }
            }
        }
    }  
`;


/*
 * Public Elements
 */
const IndexPage = () => {
    const setContrast = useContext(ContrastContext);
    setContrast(false);
    const {t} = useTranslation();

    return (
        <>
            <SEO title={t('Home')} ogTitle={t('Switch: Payment Orchestration Platform')} />

            <Helmet>
                <meta name="google-site-verification" content="1rJauHMDzCBd8wCDQZYjsWmMWPVGy89D5FD2HFSaEOY" />
            </Helmet>

            <WithAndWithoutSwitch />

            <OurSolutionsYourChoice />

            <BuiltForDevelopers />

            <ElevateYourBusiness />

            <EndOfPageCTA link="/about/contact-sales" linkText={t('Contact Sales')} cssStyles={ContactSalesCTAStyles}>
                <H2>
                    <Trans>
                        <span className="thin">Ready for your</span>
                        {' '}
                        last payments integration?
                    </Trans>
                </H2>
                <H4Mono><Trans>We&apos;re here to make it happen.</Trans></H4Mono>
            </EndOfPageCTA>
        </>
    );
};


/*
 * Exports
 */
export default IndexPage;
