import React from 'react';
import PropTypes from 'prop-types';

import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import styled from '@emotion/styled';

import {BaseHomepageCard} from './BaseHomepageCards';
import {H2, H4} from '../common/Typography';
import {breakpoints, colors, ContentContainer, fontWeights} from '../../styles/theme';
import Circle from '../../images/homepage/circle.png';
import Triangle from '../../images/homepage/triangle.png';
import SingleIntegrationIcon from '../../images/homepage/single-integration.png';
import EventSourcingIcon from '../../images/homepage/event-sourcing.png';
import JSONVideo from '../../images/json.mp4';
import JSONVideoFrame from '../../images/json.png';


/*
 * Private Elements
 */
const StyledDevelopmentCard = styled(BaseHomepageCard)`
    ${H4} {
        font-size: 20px;
        line-height: 24px;
        font-weight: ${fontWeights.semiBold};
        color: ${colors.purple};
    }
`;

const DevelopmentCard = ({title, image, description, url, linkTitle}) => (
    <StyledDevelopmentCard
        title={title}
        image={image}
        description={description}
        url={url}
        linkTitle={linkTitle}
        coloredCta={false}
        linkTarget="_blank"
    />
);

DevelopmentCard.propTypes = {
    title: PropTypes.string.isRequired,
    image: PropTypes.node.isRequired,
    description: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    linkTitle: PropTypes.string.isRequired,
};

const StyledBuiltForDevelopers = styled.div`
    background-color: ${colors.black};
    background-image: url(${Triangle}), url(${Circle});
    background-position: left top, right bottom;
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-size: 25%;
    margin: -175px 25px 0 25px;
    color: ${colors.white};

    ${ContentContainer} {
        padding-top: 50px;
        padding-bottom: 50px;

        ${H2} {
            margin-bottom: 40px;
            font-weight: ${fontWeights.semiBold};
        }

        .json {
            svg {
                width: 100%;
                height: auto;
            }

            video {
                width: 100%;
            }
        }
    }

    @media (min-width: ${breakpoints.md}) {
        ${ContentContainer} {
            padding: 75px 0 50px 30px;

            .json {
                margin-top: -50px;
                padding-right: 30px;
            }
        }
    }
`;


/*
 * Public Elements
 */
const BuiltForDevelopers = () => {
    const {t} = useTranslation();

    const singleIntegrationCard = {
        title: t('Single, one-time integration'),
        image: SingleIntegrationIcon,
        description: t(
            'By integrating with Switch, you get access to every present and future payment method, as well as all the '
            + 'tools required to run a professional and comprehensive payments operation. We offer the last payment '
            + 'integration you will ever need.',
        ),
        url: 'https://switchpayments.com/docs/integration',
        linkTitle: t('Check our integration options'),
    };
    const eventSourcingCard = {
        title: t('Event-Sourcing'),
        image: EventSourcingIcon,
        description: t(
            'Lifecycle events are the best way to keep track of transactions\' statuses on the server-side, and update '
            + 'your databases and systems accordingly. Gain insight into transactions at every instance.',
        ),
        url: 'https://switchpayments.com/docs/events',
        linkTitle: t('Check out event handling'),
    };

    return (
        <StyledBuiltForDevelopers>
            <ContentContainer>
                <div className="row no-gutters">
                    <div className="col-12">
                        <H2><Trans>Built by developers, for developers.</Trans></H2>
                    </div>
                </div>

                <div className="row no-gutters">
                    <div className="col-12 col-md-5 offset-md-1 order-md-2 json">
                        <video
                            src={JSONVideo}
                            poster={JSONVideoFrame}
                            autoPlay
                            loop
                            muted
                            controls={false}
                            playsInline
                        />
                    </div>

                    <div className="col-12 col-md-6 order-md-1">
                        <div className="row no-gutters">
                            <div className="col-12 col-xl-6">
                                <DevelopmentCard
                                    title={singleIntegrationCard.title}
                                    image={singleIntegrationCard.image}
                                    description={singleIntegrationCard.description}
                                    url={singleIntegrationCard.url}
                                    linkTitle={singleIntegrationCard.linkTitle}
                                />
                            </div>
                            <div className="col-12 col-xl-6">
                                <DevelopmentCard
                                    title={eventSourcingCard.title}
                                    image={eventSourcingCard.image}
                                    description={eventSourcingCard.description}
                                    url={eventSourcingCard.url}
                                    linkTitle={eventSourcingCard.linkTitle}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </ContentContainer>
        </StyledBuiltForDevelopers>
    );
};


/*
 * Exports
 */
export default BuiltForDevelopers;
