/** @jsx jsx */
import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {css, jsx} from '@emotion/react';
import styled from '@emotion/styled';

import {H4, P, CTALink, CTALinkDark, Link} from '../common/Typography';
import {colors} from '../../styles/theme';
import {CTA, CTAArrow} from '../common/Clickables';


/*
 * Private Elements
 */
const BaseHomepageCardCss = css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: ${colors.transparent};
    padding: 20px;
    margin: 10px;
    height: 100%;

    .logo {
        margin-bottom: 20px;

        img {
            width: 60px;
        }
    }

    ${H4} {
        margin-bottom: 10px;
    }

    ${P} {
        margin-bottom: 25px;
    }

    ${CTALink},
    ${CTALinkDark} {
        font-size: 11px;
        letter-spacing: 2px;
    }
`;

const StyledBaseHomepageCard = styled.div`
    ${BaseHomepageCardCss};
`;

const StyledBaseHomepageCardLink = styled(Link)`
    ${BaseHomepageCardCss};

    &:hover,
    &:focus {
        color: ${colors.black};
        text-decoration: none;
    }

    .link {
        display: flex;
        align-items: center;

        .link-title {
            padding-right: 15px;
        }

        .link-arrow {
            min-width: 44px;
        }
    }
`;

/*
 * Public Elements
 */
const BaseHomepageCardLink = ({
    title,
    image,
    description,
    url,
    linkTitle,
    coloredCta = false,
    cssStyles = null,
}) => {
    const [linkIsHovered, setLinkIsHovered] = useState(false);

    // eslint-disable-next-line i18next/no-literal-string
    const className = linkIsHovered ? 'hover' : '';

    return (
        <StyledBaseHomepageCardLink
            to={url}
            onMouseEnter={() => setLinkIsHovered(true)}
            onMouseLeave={() => setLinkIsHovered(false)}
            css={cssStyles}
        >
            <div>
                <div className="logo">
                    <img src={image} alt="" />
                </div>

                <H4>{title}</H4>

                <P>{description}</P>
            </div>

            <CTALink as="span" className="link">
                <span className="link-title">{linkTitle}</span>
                <CTAArrow classNames={`link-arrow ${className}`} colored={coloredCta} />
            </CTALink>
        </StyledBaseHomepageCardLink>
    );
};

BaseHomepageCardLink.propTypes = {
    title: PropTypes.string.isRequired,
    image: PropTypes.node.isRequired,
    description: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    linkTitle: PropTypes.string.isRequired,
    coloredCta: PropTypes.bool,
    cssStyles: PropTypes.object,
};

BaseHomepageCardLink.defaultProps = {
    coloredCta: false,
    cssStyles: null,
};


const BaseHomepageCard = ({
    title,
    image,
    description,
    url,
    linkTitle,
    linkTarget = '_self',
    coloredCta = false,
    cssStyles = null,
}) => (
    <StyledBaseHomepageCard css={cssStyles}>
        <div>
            <div className="logo">
                <img src={image} alt="" />
            </div>

            <H4>{title}</H4>

            <P>{description}</P>
        </div>

        <CTA to={url} colored={coloredCta} target={linkTarget}>{linkTitle}</CTA>
    </StyledBaseHomepageCard>
);

BaseHomepageCard.propTypes = {
    title: PropTypes.string.isRequired,
    image: PropTypes.node.isRequired,
    description: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    linkTitle: PropTypes.string.isRequired,
    linkTarget: PropTypes.string,
    coloredCta: PropTypes.bool,
    cssStyles: PropTypes.object,
};

BaseHomepageCard.defaultProps = {
    linkTarget: '_self',
    coloredCta: false,
    cssStyles: null,
};


/*
 * Exports
 */
export {
    BaseHomepageCard,
    BaseHomepageCardLink,
};
