import React from 'react';
import PropTypes from 'prop-types';

import {Trans} from 'gatsby-plugin-react-i18next';
import styled from '@emotion/styled';

import {H2, H5, Hero, P} from '../common/Typography';
import AnimatedBackground from '../common/AnimatedBackground';
import {HeroBackgroundSvgUrlEncoded, HeroMobileBackgroundSvgUrlEncoded} from '../../helpers/constants/Homepage';
import CheckCircle from '../../images/homepage/check-circle.no-inline.svg';
import XCircle from '../../images/homepage/x-circle.no-inline.svg';
import WithoutSwitch from '../../images/homepage/without.svg';
import WithSwitch from '../../images/homepage/with.svg';
import {breakpoints, colors, fontWeights} from '../../styles/theme';



/*
 * Private Elements
 */
const PaymentsPlatformContainer = styled.div`
    .wrapped {
        background: url("data:image/svg+xml, ${HeroMobileBackgroundSvgUrlEncoded}") no-repeat center center/cover;
        box-shadow: 3px 5px 5px 0 rgba(0, 0, 0, 0.5);
        color: ${colors.white};
        height: 100vh;
        display: flex;
        z-index: 1;
        position: relative;

        .content {
            margin: auto;
            padding: 20px;
            width: 100%;
            text-align: center;

            ${Hero} {
                margin-bottom: 35px;
                font-weight: ${fontWeights.semiBold};
            }

            ${H5} {
                font-weight: ${fontWeights.regular};
                color: ${colors.mediumGrey};
            }
        }
    }

    @media (min-width: ${breakpoints.md}) {
        .wrapped {
            background: url("data:image/svg+xml, ${HeroBackgroundSvgUrlEncoded}") no-repeat center center/cover;
          
            .content {
                padding: 25px;
                max-width: 800px;

                ${Hero} {
                    font-size: 70px;
                    line-height: 80px;
                }
            }
        }
    }
`;

const WithWithoutSwitchContainer = styled.div`
    svg {
        width: 100%;
        height: auto;
    }

    .animated-background {
        color: ${colors.white};

        .with-and-without-switch {
            display: flex;
            flex-direction: row;
            text-align: center;

            .with,
            .without {
                padding-top: 50px;

                .content {
                    & > {
                        svg {
                            margin-bottom: 35px;
                            padding: 0 25px;
                        }
                    }
                }

                .items {
                    width: 100%;
                    margin: 0 auto 70px auto;
                    padding-left: 25px;
                    padding-right: 25px;
                }

                ${H2} {
                    font-weight: ${fontWeights.bold};
                    margin-bottom: 40px;

                    .thin {
                        font-weight: ${fontWeights.regular};
                    }
                }

                ${P} {
                    color: ${colors.mediumGrey};
                }
            }

            .with {
                background-color: rgba(255, 255, 255, 0.2);
                clip-path: polygon(0 65px, 100% 0, 100% 100%, 0 100%);
                padding-top: 100px;
                margin-top: -65px;

                .items {
                    .item {
                        ${P} {
                            color: ${colors.white};
                        }
                    }
                }
            }
        }
    }

    @media (min-width: ${breakpoints.md}) {
        svg {
            max-width: 364px;
        }
      
        .animated-background {
            .with-and-without-switch {
                .with,
                .without {
                    margin-top: 0;
                    padding-top: 50px;

                    .content {
                        &.left {
                            max-width: 650px;
                            margin-left: auto;
                        }

                        &.right {
                            max-width: calc(650px + 6vw);
                            margin-right: auto;
                        }

                        svg {
                            padding: 0;
                        }
                    }

                    .items {
                        margin-bottom: 50px;
                    }
                }

                .with {
                    position: relative;
                    flex-basis: calc(50% + 3vw);
                    max-width: unset;
                    clip-path: polygon(0 0, 100% 0, 100% 100%, 6vw 100%);

                    .items {
                        padding-left: calc(6vw + 25px);

                        .item {
                            padding-right: 25px;
                        }
                    }
                }

                .without {
                    flex-basis: calc(50% - 3vw);

                    .items {
                        padding-right: 25px;

                        .item {
                            padding-left: 25px;
                        }
                    }
                }
            }
        }
    }

    @media (min-width: ${breakpoints.lg}) {
        .animated-background {
            .with-and-without-switch {
                .with,
                .without {
                    & > {
                        svg {
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
`;

const StyledWithOrWithoutSwitchBullet = styled.span`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;

    img {
        width: 22px;
        height: 22px;
    }

    ${P} {
        margin-left: 10px;
        text-align: left;
        font-weight: ${fontWeights.medium};
    }
`;

const WithSwitchBullet = ({children}) => (
    <StyledWithOrWithoutSwitchBullet>
        <img src={CheckCircle} alt="" />
        <P>{children}</P>
    </StyledWithOrWithoutSwitchBullet>
);

WithSwitchBullet.propTypes = {
    children: PropTypes.node.isRequired,
};

const WithoutSwitchBullet = ({children}) => (
    <StyledWithOrWithoutSwitchBullet>
        <img src={XCircle} alt="" />
        <P>{children}</P>
    </StyledWithOrWithoutSwitchBullet>
);

WithoutSwitchBullet.propTypes = {
    children: PropTypes.node.isRequired,
};


/*
 * Public Elements
 */
const WithAndWithoutSwitch = () => (
    <WithWithoutSwitchContainer>
        <AnimatedBackground>
            <PaymentsPlatformContainer>
                <div className="wrapped">
                    <div className="content">
                        <Hero><Trans>Payments Platform as a Service.</Trans></Hero>

                        <H5>
                            <Trans>
                                We&apos;re empowering merchants, PSPs, and acquirers to level up their payments
                                stack.
                            </Trans>
                        </H5>
                    </div>
                </div>
            </PaymentsPlatformContainer>

            <div className="with-and-without-switch row no-gutters">
                <div className="without col-12 col-md-6">
                    <div className="content left">
                        <H2>
                            <Trans>
                                Without
                                {' '}
                                <span className="thin">Switch</span>
                            </Trans>
                        </H2>

                        <WithoutSwitch />

                        <div className="row no-gutters items">
                            <div className="col-12 col-md-6 item">
                                <WithoutSwitchBullet>
                                    <Trans>Overwhelming number of integrations</Trans>
                                </WithoutSwitchBullet>
                            </div>
                            <div className="col-12 col-md-6 item">
                                <WithoutSwitchBullet>
                                    <Trans>Inefficient processing operation</Trans>
                                </WithoutSwitchBullet>
                            </div>
                            <div className="col-12 col-md-6 item">
                                <WithoutSwitchBullet>
                                    <Trans>Costly regulatory requirements</Trans>
                                </WithoutSwitchBullet>
                            </div>
                            <div className="col-12 col-md-6 item">
                                <WithoutSwitchBullet>
                                    <Trans>Low negotiation power</Trans>
                                </WithoutSwitchBullet>
                            </div>
                        </div>
                    </div>
                </div>

                {/*
                    TODO: It would be nice to be able to add a shadow to this element, but it has prover to be difficult
                    due to using clip-path. In the future if we manage to do it it would be cool, but not critical for
                    the MVP
                */}
                <div className="with col-12 col-md-6">
                    <div className="content right">
                        <H2>
                            <Trans>
                                With
                                {' '}
                                <span className="thin">Switch</span>
                            </Trans>
                        </H2>

                        <WithSwitch />

                        <div className="row no-gutters items">
                            <div className="col-12 col-md-6 item">
                                <WithSwitchBullet>
                                    <Trans>One payment integration for a unified data structure</Trans>
                                </WithSwitchBullet>
                            </div>
                            <div className="col-12 col-md-6 item">
                                <WithSwitchBullet>
                                    <Trans>Optimized acceptance and processing rates</Trans>
                                </WithSwitchBullet>
                            </div>
                            <div className="col-12 col-md-6 item">
                                <WithSwitchBullet>
                                    <Trans>Regulatory compliance offload</Trans>
                                </WithSwitchBullet>
                            </div>
                            <div className="col-12 col-md-6 item">
                                <WithSwitchBullet>
                                    <Trans>
                                        Competitive advantage in the access to the entire payments value chain
                                    </Trans>
                                </WithSwitchBullet>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AnimatedBackground>
    </WithWithoutSwitchContainer>
);


/*
 * Exports
 */
export default WithAndWithoutSwitch;
