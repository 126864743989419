import React from 'react';
import PropTypes from 'prop-types';

import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import styled from '@emotion/styled';

import {BaseHomepageCardLink} from './BaseHomepageCards';
import {Hero, H6} from '../common/Typography';
import {breakpoints, colors, ContentContainer, fontWeights} from '../../styles/theme';
import AnalyticsIcon from '../../images/homepage/analytics.png';
import DynamicRoutingIcon from '../../images/homepage/dynamic-routing.png';
import ProcessingIcon from '../../images/homepage/processing.png';
import ReconciliationIcon from '../../images/homepage/reconciliation.png';
import RiskIcon from '../../images/homepage/risk.png';
import TerminalIcon from '../../images/homepage/terminal.png';
import VaultIcon from '../../images/homepage/vault.png';


/*
 * Private Elements
 */
const StyledOurSolutionsYourChoice = styled.div`
    background-color: ${colors.white};

    ${ContentContainer} {
        .content {
            margin: 120px auto 0;
            text-align: center;

            ${Hero} {
                margin-bottom: 30px;
            }

            ${H6} {
                max-width: 600px;
                margin: auto;
                font-weight: ${fontWeights.regular};
            }
        }
    }

    .cards {
        padding-top: 70px;
        padding-bottom: 250px;
        background: linear-gradient(to top, ${colors.ghostWhite}, ${colors.white});

        .solution-card {
            margin-bottom: 20px;
        }
    }

    @media (min-width: ${breakpoints.md}) {
        ${ContentContainer} {
            padding-left: 65px;
            padding-right: 65px;
        }
    }
`;

const SolutionCard = ({title, image, description, url, linkTitle}) => (
    <BaseHomepageCardLink
        title={title}
        image={image}
        description={description}
        url={url}
        linkTitle={linkTitle}
        coloredCta
    />
);

SolutionCard.propTypes = {
    title: PropTypes.string.isRequired,
    image: PropTypes.node.isRequired,
    description: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    linkTitle: PropTypes.string.isRequired,
};


/*
 * Public Elements
 */
const OurSolutionsYourChoice = () => {
    const {t} = useTranslation();

    const cards = [
        {
            title: t('Developer Independency'),
            image: ProcessingIcon,
            description: t('Accept additional payment methods and providers without any changes to the code.'),
            url: '/product/processing',
            linkTitle: t('Discover Processing'),
        },
        {
            title: t('Payments Performance Optimization'),
            image: DynamicRoutingIcon,
            description: t('Use custom rules to route transactions between payment providers in real-time.'),
            url: '/product/dynamic-routing',
            linkTitle: t('Discover Dynamic Routing'),
        },
        {
            title: t('Tokenization'),
            image: VaultIcon,
            description: t('Put an end to declines related to fraud, expired cards, or lost credentials.'),
            url: '/product/vault',
            linkTitle: t('Discover Vault'),
        },
        {
            title: t('Automatic Reconciliation'),
            image: ReconciliationIcon,
            description: t('Pinpoint mismatches, fees, and reconciliation errors across multiple payment providers.'),
            url: '/product/reconciliation',
            linkTitle: t('Discover Reconciliation'),
        },
        {
            title: t('Dynamic 3DS'),
            image: RiskIcon,
            description: t('Authenticate specific transactions through EMV 3DS using a variety of criteria.'),
            url: '/product/risk',
            linkTitle: t('Discover Risk'),
        },
        {
            title: t('Big Data'),
            image: AnalyticsIcon,
            description: t('Convert information and valuable insights taken from big data into better decisions.'),
            url: '/product/analytics',
            linkTitle: t('Discover Analytics'),
        },
        {
            title: t('Omnichannel'),
            image: TerminalIcon,
            description: t('Unify your payments stack across every channel through a single infrastructure.'),
            url: '/product/terminal',
            linkTitle: t('Discover Terminal'),
        },
    ];

    const cardsContent = cards.map(content => (
        <div className="row no-gutters col-12 col-md-6 col-lg-4 col-xl-3 solution-card" key={content.url}>
            <SolutionCard
                title={content.title}
                image={content.image}
                description={content.description}
                url={content.url}
                linkTitle={content.linkTitle}
            />
        </div>
    ));

    return (
        <StyledOurSolutionsYourChoice>
            <ContentContainer>
                <div className="content">
                    <Hero><Trans>Our solutions, your choice.</Trans></Hero>

                    <H6>
                        <Trans>
                            Switch&apos;s microservices architecture allows exposure to our applications independently
                            through different APIs on a modular basis. Acquirers can consume specific applications on an
                            à la carte model, allowing them to capitalize on existing legacy systems and prepare for the
                            challenges ahead.
                        </Trans>
                    </H6>
                </div>
            </ContentContainer>

            <div className="cards">
                <ContentContainer>
                    <div className="row no-gutters">
                        {cardsContent}
                    </div>
                </ContentContainer>
            </div>
        </StyledOurSolutionsYourChoice>
    );
};


/*
 * Exports
 */
export default OurSolutionsYourChoice;
